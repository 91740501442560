import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ArrowLeft, CircleCheckBig, XCircleIcon, BadgePlus } from "lucide-react";
import axios from "axios";
import Home from "./Home";
import { toast } from "sonner";
import { API_URL } from "../../config";
import { getBase64FromDataURL } from "../Cod/helper";
import CustomSelectInput from "./CustomSelectInput";

const initialFormState = {
  hub_id: "",
  hub_name: "",
};

const initialFormData = {
  date: "",
  runsheet_id: "",
  gps_device_number: "",
  opening_km: "",
  closing_km: "",
  gps_km: "",
  fresh_load: "",
  out_for_delivery: "",
  delivered: "",
  undelivered: "",
  out_for_pickup: "",
  pickup_done: "",
  pickup_not_done: "",
  vehicle_image: "",
  odometer_image: "",
  status: "",
  vehicle_number: "",
};

const VendorVehicle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(initialFormData);
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error,] = useState(null);
  const [hubs, setHubs] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [addVehicleId, setAddVehicleId] = useState("");
  const [, setVehicleType] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState([]);
  const [vehicleNumberInput, setVehicleNumberInput] = useState('');
  const [, setFormDataHubsetup] = useState(initialFormState);
  const [reviewInfo, setReviewInfo] = useState({
    employee_code: "",
    hub_name: "",
    name: "",
  });
  const [reviewErrors, setReviewErrors] = useState({});
  const [closingData, setClosingData] = useState(null);
  const [selectedVehicleIdForClosingForm, setSelectedVehicleIdForClosingForm] = useState("");
  const BASE_URL = API_URL;

  // Find the selected vehicle data
  const selectedVehicleDataForCloseForm = closingData?.find((item) => {
    console.log("Checking vehicle_id:", item.vehicle_id, selectedVehicleIdForClosingForm);
    return item.id === parseInt(selectedVehicleIdForClosingForm);
  });


  const handleVehicleChangeForCloseForm = (e) => {
    setSelectedVehicleIdForClosingForm(e.target.value);
  };

  const validateForm = () => {
    const errors = {};

    // Step 1 validation
    if (currentStep === 1 && formData.status === 'opening') {
      if (!reviewInfo.hub_name || reviewInfo.hub_name.trim() === "") {
        errors.hub_name = "Hub Name is required.";
      }
      // Validate vendor_name
      if (!reviewInfo.vendor_name || reviewInfo.vendor_name === "") {
        errors.vendor_name = "Vendor Name is required.";
      }
      // Validate vehicle_type
      if (!reviewInfo.vehicle_type || reviewInfo.vehicle_type === "") {
        errors.vehicle_type = "Vehicle Type is required.";
      }
      // Validate vehicle_number
      if (!reviewInfo.vehicle_number || reviewInfo.vehicle_number === "") {
        errors.vehicle_number = "Vehicle Number is required.";
      }
      // Validate other fields
      if (!formData.date) {
        errors.date = "Date is required.";
      }
    }else if (currentStep === 1 && formData.status === 'closing') {
      if (!selectedVehicleIdForClosingForm || selectedVehicleIdForClosingForm === "") {
        errors.vehicle_number = "Vehicle Number is required.";
      }
    }

    // Step 2 validation
    else if (currentStep === 2) {
      // Validate image fields
      if (!formData.vehicle_image) {
        errors.vehicle_image = "Vehicle Image is required.";
      }

      if (!formData.odometer_image) {
        errors.odometer_image = "Odometer Image is required.";
      }
      if (formData.status === "opening") {
        if (!formData.opening_km) {
          errors.opening_km = "Opening Km is required.";
        }
      } else {
        if (!formData.closing_km) {
          errors.closing_km = "Closing Km is required.";
        } else if (formData.closing_km <= formData.opening_km) {
          errors.closing_km = "Closing Km should be greater than Opening Km.";
        }
        if (!formData.gps_km) {
          errors.gps_km = "GPS Km is required.";
        }
      }
    }

    // Step 3 validation

    // Set the error state
    setReviewErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };


  const handleNextClick = async () => {
    // Validate the form before proceeding
    if (!validateForm()) {
      console.log("validateForm", reviewErrors)
      return;
    }
    // setIsLoading(true);
    // Proceed to the next step if the form is valid
    if (formData.status === "opening" && currentStep === 2) {
      try {
        const response = await CreateTripForVendorVehicle(vendorId);
        console.log("response", response)
        setCurrentStep(4);
      } catch (error) {
        console.error("CreateTripForVendorVehicle failed:", error);
        toast.error("Failed to create trip. Please try again.");
      }
    } else if (formData.status === "closing" && currentStep === 3) {
      try {
        const response = await CreateTripForVendorVehicle(vendorId);
        console.log("response", response)
        // setCurrentStep(currentStep + 1);
        // if (response.status >= 200 && response.status < 300) {
        setCurrentStep(currentStep + 1);
        // } else {
        // toast.error("Failed to create trip. Please try again.");
        // }
      } catch (error) {
        console.error("CreateTripForVendorVehicle failed:", error);
        toast.error("Failed to create trip. Please try again.");
      }
    } 
    else {
      if (currentStep === 2 && formData.status === "opening") {
        // If currentStep is 2 and status is opening, skip to step 4
        console.log("currentStep", currentStep)
        setCurrentStep(4);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const id = searchParams.get("id");
      if (id) {
        try {
          const response = await axios.get(`${BASE_URL}/employee/${id}`, {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          });
          const userData = response.data.data;
          console.log("userData", userData);
          setReviewInfo((prevState) => ({
            ...prevState,
            employee_code: userData?.id || "",
            name: userData?.name,
          }));
          setFormDataHubsetup((prevState) => ({
            ...prevState,
            hub_id: userData?.id || "",
            name: userData?.name,
          }));
          let designation = userData?.designation;
          let hubRole = "";

          if (!designation || designation === "Hub Incharge")
            hubRole = "hubsInCharge";
          else if (designation === "Level 1") hubRole = "hubsLevel1";
          else hubRole = "hubsLevel2";

          // let hubIds = userData[hubRole]
          console.log(userData[hubRole], "--- user data ---");
          setHubs(userData[hubRole]);
        } catch (err) {
          toast.error("Failed to fetch Employee data. Please try again.");
          console.error("Error fetching user data:", err);
        }
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, [searchParams, BASE_URL]);

  // handleInputChange function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Update the specific form field
    }));
  };

  const handleReviewInfoChange = (event) => {
    const { name, value } = event.target;
    setReviewInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    console.log("value-hub-id", value);
    fetchDataForVendorIdAndVehicleId(value);
  };

  const handleVendorNameChange = (event) => {
    const { name, value } = event.target;
    setVendorId((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    setReviewInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    console.log("value-vendor-id", value);
    // Assuming 'vendor' is an array of vendor objects
    const filteredVehicleData = vendor.filter((v) => v.id === value);
    console.log("Filtered Vehicle Data:", filteredVehicleData[0].vehicles);
    setVehicle(filteredVehicleData[0].vehicles);
  };

  const handleVehicleTypeChange = (event) => {
    // console.log(value)
    const { name, value } = event.target;
    // console.log("251",name,value)
    setVehicleType((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    setVehicleId(value);
    setReviewInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    // setReviewInfo({ ...reviewInfo, [name]: value });
    console.log("value-vehicle-type-275", value);
    console.log("vehicle", vehicle)
    const filterDataAccToVehicleType = vehicle.filter((veh) => {
      return veh.id === parseInt(value);
    });

    console.log("Filtered Data from vehicle type:", filterDataAccToVehicleType);
    setVehicleNumber(filterDataAccToVehicleType)
    setAddVehicleId(filterDataAccToVehicleType[0].id)
  };

  const handleVehicleNumberChange = async (event) => {
    // console.log(value)
    const { name, value } = event.target;
    console.log("251", name, value)
    setVehicleNumberInput((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    setReviewInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    console.log("value-vehicle-number", value);
  }

  const handleRightClick = async (payload) => {
    // Find the ID where vehicle_number is null
    const Id = addVehicleId
    console.log("vehicleNumber", Id)
    try {

      const response = await axios.put(`${BASE_URL}/vendor/vehicle/${Id}`,
        payload,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        });
      const Data = response.data.data;
      console.log("Update vehicle number Data:", Data);
      // Assuming the new vehicle number is in Data.vehicle_number
      if (Data && Data.vehicle_number) {
        // Get the last vehicle number from the array
        const latestVehicleNumber = Data.vehicle_number[Data.vehicle_number.length - 1];
        console.log("latestVehicleNumber", latestVehicleNumber)
        // Update the vehicleNumber state with the newly added number
        setVehicleNumber((prevVehicleNumbers) => [
          // ...prevVehicleNumbers, // Spread the existing vehicle numbers
          { vehicle_number: Data.vehicle_number }, // Add the new vehicle number
        ]);
      }
      toast("vehicle number added successfully")
    } catch (err) {
      console.log(err)
    }
  };


  const fetchDataForVendorIdAndVehicleId = async (id) => {
    console.log(id, BASE_URL);
    try {
      const response = await axios.get(`${BASE_URL}/vendor/hub-vendors/${id}`, {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      });
      const Data = response.data.data;
      console.log("Data", Data);
      setVendor(Data);
    } catch (err) {
      toast.error("Failed to fetch VendorId And VehicleId. Please try again.");
      console.error("Error fetching user data:", err);
    }
  };

  const CreateTripForVendorVehicle = async () => {
    // console.log("status",formData.status)
    const payload = {
      vendor_id: vendorId.vendor_name || undefined,
      vehicle_id: parseInt(vehicleId) || selectedVehicleDataForCloseForm?.id || undefined,
      vehicle_number: vehicleNumberInput?.vehicle_number || undefined,
      hub_id: reviewInfo.hub_name || undefined,
      date: formData.date || selectedVehicleDataForCloseForm?.date || undefined,
      runsheet_id: formData.runsheet_id || selectedVehicleDataForCloseForm?.runsheet_id || undefined,
      gps_device_number: formData.gps_device_number || selectedVehicleDataForCloseForm?.gps_device_number || undefined,
      opening_km: parseInt(formData.opening_km) || selectedVehicleDataForCloseForm?.opening_km || undefined,
      closing_km: parseInt(formData.closing_km) || undefined,
      gps_km: parseInt(formData.gps_km) || selectedVehicleDataForCloseForm?.gps_km || undefined,
      fresh_load: parseInt(formData.fresh_load) || selectedVehicleDataForCloseForm?.fresh_load || undefined,
      out_for_delivery: parseInt(formData.out_for_delivery) || selectedVehicleDataForCloseForm?.out_for_delivery || undefined,
      delivered: parseInt(formData.delivered) || selectedVehicleDataForCloseForm?.delivered || undefined,
      undelivered: parseInt(formData.undelivered) || selectedVehicleDataForCloseForm?.undelivered || undefined,
      out_for_pickup: parseInt(formData.out_for_pickup) || selectedVehicleDataForCloseForm?.out_for_delivery || undefined,
      pickup_done: parseInt(formData.pickup_done) || selectedVehicleDataForCloseForm?.pickup_done || undefined,
      pickup_not_done: parseInt(formData.pickup_not_done) || selectedVehicleDataForCloseForm?.pickup_not_done || undefined,
      vehicle_image: getBase64FromDataURL(formData.vehicle_image) || undefined,
      odometer_image: getBase64FromDataURL(formData.odometer_image) || undefined,
    };
    // Remove fields with undefined values from the payload
    const filteredPayload = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v !== undefined));
    console.log("payload", payload)
    try {
      let response;
      if (formData.status === "opening") {
        const response = await axios.post(
          `${BASE_URL}/trip/opening`, // URL for opening
          payload, // FormData as payload
          {
            headers: {
              "ngrok-skip-browser-warning": "true", // Add headers here
            },
          }
        );

        // Handle the response for opening as needed
        console.log("Opening response:", response.data);
        const Data = response.data.data;
        console.log("Opening response-vendor-Data", Data);
        setVendor(Data); // Assuming setVendor is a state setter function

      } else if (formData.status === "closing") {
        const response = await axios.post(
          `${BASE_URL}/trip/closing/${selectedVehicleIdForClosingForm}`, // URL for closing
          filteredPayload, // FormData as payload
          {
            headers: {
              "ngrok-skip-browser-warning": "true", // Add headers here
            },
          }
        );

        // Handle the response for closing as needed
        console.log("Closing response:", response.data);
        const Data = response.data.data;
        console.log("Closing response-vendor-Data", Data);
        setVendor(Data); // Assuming setVendor is a state setter function
      }
      return response;
    } catch (err) {
      toast.error("Failed to fetch VendorId And VehicleId. Please try again.");
      console.error("Error fetching user data:", err);
    }
  };

  const handleStatusChange = async (e) => {
    const { value } = e.target;
    setFormData((prevReviewInfo) => ({
      ...prevReviewInfo,
      status: value,
    }));

    console.log("reviewInfo.hub_name")
    const id = reviewInfo.hub_name;
    if (value === "closing") {
      // API call when status is "closing"
      try {
        const response = await axios.get(`${BASE_URL}/trip/open/${id}`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        });
        const closingData = response.data.data;
        setClosingData(response.data.data);
        console.log("Closing Data:", closingData);

        // Populate the form with closing data
        setFormData((prevFormData) => ({
          ...prevFormData,
          closing_km: closingData.closing_km || "",
          gps_km: closingData.gps_km || "",
          fresh_load: closingData.fresh_load || "",
          out_for_delivery: closingData.out_for_delivery || "",
          delivered: closingData.delivered || "",
          undelivered: closingData.undelivered || "",
          out_for_pickup: closingData.out_for_pickup || "",
          pickup_done: closingData.pickup_done || "",
          pickup_not_done: closingData.pickup_not_done || "",
        }));
      } catch (err) {
        console.error("Failed to fetch data for closing:", err);
      }
    }
  };

  // const resetForm = () => {
  //   if (isUploading || isAnalyzing) return;
  //   const id = searchParams.get("id");
  //   setFormData({
  //     ...initialFormState,
  //     employee_id: id || "",
  //   });
  //   setPreview(null);
  //   setUploadStatus("");
  //   setAnalysisResult(null);
  //   setCurrentStep(1);
  //   setFormErrors({});
  // };

  const handleCapturedImage = (image) => {
    console.log("Image received from Home:", image);
    setFormData((prevFormData) => ({
      ...prevFormData,
      vehicle_image: image, // Store the image as data URL
    }));

    console.log("Image captured:", image);
  };

  const handleCapturedOdometerImage = (odometer_image) => {
    console.log("Image received from Home:", odometer_image);
    setFormData((prevFormData) => ({
      ...prevFormData,
      odometer_image: odometer_image, // Store the image as data URL
    }));

    console.log("Image odometer captured:", odometer_image);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  }

  const renderReviewInformation = () => (
    <>
      <h3 className="text-xl font-medium mb-4 text-gray-200">
        <p
          className="text-sm font-medium text-gray-100 opacity-65"
          style={{ fontSize: "0.75rem" }}
        >
          Step 1 of 3
        </p>
        Review Your Information
      </h3>
      {isLoading ? (
        <p className="text-white">Loading user data...</p>
      ) : (
        <>
          {error && <p className="text-yellow-500 mb-4">{error}</p>}
          <div className="space-y-4 mb-6">
            <div>
              <label
                htmlFor="hub_name"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Hub Id
              </label>
              {hubs && hubs.length > 0 ? (
                <select
                  id="hub_name"
                  name="hub_name"
                  value={reviewInfo.hub_name}
                  onChange={handleReviewInfoChange}
                  className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                >
                  <option value="">Select a hub</option>
                  {hubs.map((hub) => (
                    <option key={hub.id} value={hub.id}>
                      {`${hub.location} (${hub.state} - ${hub.zone})`}
                    </option>
                  ))}
                </select>
              ) : (
                <div
                  className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4"
                  role="alert"
                >
                  <p className="font-bold">No Hubs Assigned</p>
                  <p>
                    There are no hubs attached to your name. Please contact your
                    administrator for assistance.
                  </p>
                </div>
              )}
              {reviewErrors["hub_name"] && (
                <p className="mt-1 text-sm text-red-500">
                  {reviewErrors["hub_name"]}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="status"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Form Status
              </label>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleStatusChange}
                className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
              >
                <option value="">Select Status</option>
                <option value="opening">Opening Form</option>
                <option value="closing">Closing Form</option>
              </select>
            </div>

            {formData.status === "opening" && (
              <>
                <div>
                  <label
                    htmlFor="vendor_name"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Vendor Name
                  </label>
                  <select
                    id="vendor_name"
                    name="vendor_name"
                    value={reviewInfo.vendor_name}
                    onChange={handleVendorNameChange}
                    className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                  >
                    <option value="">Select a Vendor</option>
                    {vendor.map((item) => (
                      <option key={item.id} value={item.id}>
                        {`${item.name} - ${item?.vehicles[0]?.vehicle_mode}`}
                      </option>
                    ))}
                  </select>
                  {reviewErrors.vendor_name && (
                    <p className="text-red-500">{reviewErrors.vendor_name}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="vehicle_type"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Vehicle Type
                  </label>
                  <select
                    id="vehicle_type"
                    name="vehicle_type"
                    value={reviewInfo.vehicle_type}
                    onChange={handleVehicleTypeChange}
                    className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                  >
                    <option value="">Select a Vehicle Type</option>
                    {vehicle.map((item) => (
                      <option key={item.id} value={item.id}>
                        {`${item.vehicle_type}`}
                      </option>
                    ))}
                  </select>
                  {reviewErrors.vehicle_type && (
                    <p className="text-red-500">{reviewErrors.vehicle_type}</p>
                  )}
                </div>
                <div className="relative mb-4">
                  <label
                    htmlFor="vehicle_number"
                    className="flex text-sm font-medium text-gray-300 mb-2 justify-between"
                  >
                    Vehicle Number
                    <BadgePlus size={17} onClick={handleOpenModal} />
                  </label>
                  <div className="flex items-center border rounded-md shadow-sm bg-[#1F1F1F]">
                    <select
                      id="vehicle_number"
                      name="vehicle_number"
                      value={reviewInfo.vehicle_number}
                      onChange={handleVehicleNumberChange}
                      className="block w-full bg-transparent border-none text-white p-2 cursor-pointer appearance-none"
                    >
                      <option value="">Select a Vehicle</option>

                      {vehicleNumber.map((item) => {
                        return item.vehicle_number && item.vehicle_number.length > 0 ? (
                          item.vehicle_number.map((number, index) => (
                            <option key={`${item.id}-${index}`} value={number}>
                              {number} {/* Display each vehicle number separately */}
                            </option>
                          ))
                        ) : (
                          <option key={item.id} value={item.id}>
                            No Vehicle Number
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {reviewErrors.vehicle_number && (
                      <p className="text-red-500">{reviewErrors.vehicle_number}</p>
                    )}
                </div>
                <div>
                  <label
                    htmlFor="date"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Date
                  </label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={formData.date}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                    max={new Date().toISOString().split("T")[0]}
                  />
                  {reviewErrors.date && (
                    <p className="text-red-500">{reviewErrors.date}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="runsheet_id"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Runsheet Id (Optional)
                  </label>
                  <input
                    type="number"
                    id="runsheet_id"
                    name="runsheet_id"
                    value={formData.runsheet_id}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border  rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.runsheet_id && (
                    <p className="text-red-500">{reviewErrors.runsheet_id}</p>
                  )}
                </div>
              </>
            )}
            {/* Closing Form Fields */}
            {formData.status === "closing" && closingData && (
              <>
                <div>
                  <label
                    htmlFor="vehicle_name"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Vehicle Number
                  </label>
                  <select
                    id="vehicle_number"
                    name="vehicle_number"
                    value={selectedVehicleIdForClosingForm}
                    onChange={handleVehicleChangeForCloseForm} // Update selected vehicle
                    className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                  >
                    <option value="">Select a Vehicle</option>
                    {closingData.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.vehicle_number || "No Vehicle Number"} {/* Display vehicle number */}
                      </option>
                    ))}
                  </select>
                  {reviewErrors.vehicle_number && (
                      <p className="text-red-500">{reviewErrors.vehicle_number}</p>
                    )}
                </div>
                <div>
                  <label
                    htmlFor="date"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Date
                  </label>
                  <input
                    type="text"
                    id="date"
                    name="date"
                    value={
                      selectedVehicleDataForCloseForm?.date
                        ? new Date(selectedVehicleDataForCloseForm.date)
                          .toISOString()
                          .split("T")[0]
                        : ""
                    }
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                    readOnly // This will make the input read-only
                  />
                  {reviewErrors.date && (
                    <p className="text-red-500">{reviewErrors.date}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="runsheet_id"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Runsheet Id (Optional)
                  </label>
                  <input
                    type="number"
                    id="runsheet_id"
                    name="runsheet_id"
                    value={selectedVehicleDataForCloseForm?.runsheet_id || ""}
                    onChange={(e) => handleInputChange(e)}
                    // readOnly
                    className={`block w-full bg-[#1F1F1F] border  rounded-md shadow-sm p-2 text-white`}
                  />
                  {/* {reviewErrors.runsheet_id && (
                <p className="text-red-500">{reviewErrors.runsheet_id}</p>
              )} */}
                </div>
              </>
            )}
          </div>
        </>
      )}
      <button
        onClick={handleNextClick}
        className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
        disabled={isLoading}
      >
        Next
      </button>
    </>
  );

  const renderStepTwo = () => (
    <>
      <h3 className="text-xl font-medium mb-4 text-gray-200">
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-col">
            <p
              className="text-sm font-medium text-gray-100 opacity-65"
              style={{ fontSize: "0.75rem" }}
            >
              Step 2 of 3
            </p>
            Review Your Information
          </div>
          <p
            className={`text-[16px] font-regular text-gray-100 opacity-65 flex gap-1 items-center`}
            onClick={() => {
              setCurrentStep(1);
            }}
          >
            <ArrowLeft size={18} />
            Back
          </p>
        </div>
      </h3>
      {isLoading ? (
        <p className="text-white">Loading user data...</p>
      ) : (
        <>
          {error && <p className="text-yellow-500 mb-4">{error}</p>}
          <div className="space-y-4 mb-6">
            <div>
              <label
                htmlFor="vehicle_image"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                Upload Vehicle Number Plate Image
              </label>
              <Home onCapture={handleCapturedImage} />
              {reviewErrors.vehicle_image && (
                <p className="text-red-500">{reviewErrors.vehicle_image}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="odometer_image"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                Upload Odometer Image
              </label>
              <Home onCapture={handleCapturedOdometerImage} />
              {reviewErrors.odometer_image && (
                <p className="text-red-500">{reviewErrors.odometer_image}</p>
              )}
            </div>
            {/* Conditionally render based on status */}
            {formData.status === "opening" && (
              <>
                <div>
                  <label
                    htmlFor="gps_device_number"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    GPS Device Number (Optional)
                  </label>
                  <input
                    type="number"
                    id="gps_device_number"
                    name="gps_device_number"
                    value={formData.gps_device_number}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.gps_device_number && (
                    <p className="text-red-500">{reviewErrors.gps_device_number}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="opening_kms"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Opening Kms
                  </label>
                  <input
                    type="number"
                    id="opening_km"
                    name="opening_km"
                    value={formData.opening_km}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.opening_km && (
                    <p className="text-red-500">{reviewErrors.opening_km}</p>
                  )}
                </div>
              </>
            )}

            {formData.status === "closing" && closingData && (
              <>
                <div>
                  <label
                    htmlFor="gps_device_number"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    GPS Device Number (Optional)
                  </label>
                  <input
                    type="number"
                    id="gps_device_number"
                    name="gps_device_number"
                    value={selectedVehicleDataForCloseForm?.gps_device_number}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.gps_device_number && (
                    <p className="text-red-500">{reviewErrors.gps_device_number}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="closing_km"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Closing Kms
                  </label>
                  <input
                    type="number"
                    id="closing_km"
                    name="closing_km"
                    value={formData.closing_km}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.closing_km && (
                    <p className="text-red-500">{reviewErrors.closing_km}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="gps_km"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    GPS Kms (Optional)
                  </label>
                  <input
                    type="number"
                    id="gps_km"
                    name="gps_km"
                    value={selectedVehicleDataForCloseForm?.gps_km}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {reviewErrors.gps_km && (
                    <p className="text-red-500">{reviewErrors.gps_km}</p>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
      <button
        onClick={handleNextClick}
        className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
        disabled={isLoading}
      >
        Next
      </button>
    </>
  );

  const renderStepThree = () => (
    <>
      <h3 className="text-xl font-medium mb-4 text-gray-200">
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-col">
            <p
              className="text-sm font-medium text-gray-100 opacity-65"
              style={{ fontSize: "0.75rem" }}
            >
              Step 3 of 3
            </p>
            Review Your Information
          </div>
          <p
            className={`text-[16px] font-regular text-gray-100 opacity-65 flex gap-1 items-center`}
            onClick={() => {
              setCurrentStep(2);
            }}
          >
            <ArrowLeft size={18} />
            Back
          </p>
        </div>
      </h3>
      {isLoading ? (
        <p className="text-white">Loading user data...</p>
      ) : (
        <>
          {error && <p className="text-yellow-500 mb-4">{error}</p>}
          <div className="space-y-4 mb-6">
            {/* {formData.status === "opening" && (
              <>
                <div>
                  <label
                    htmlFor="fresh_load"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Fresh Load
                  </label>
                  <input
                    type="number"
                    id="fresh_load"
                    name="fresh_load"
                    value={formData.fresh_load}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {/* {reviewErrors.fresh_load && (
                <p className="text-red-500">{reviewErrors.fresh_load}</p>
              )} */}
            {/* </div>
                <div>
                  <label
                    htmlFor="out_for_delivery"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Out For Delivery
                  </label>
                  <input
                    type="number"
                    id="out_for_delivery"
                    name="out_for_delivery"
                    value={formData.out_for_delivery}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  /> */}
            {/* {reviewErrors.out_for_delivery && (
                <p className="text-red-500">{reviewErrors.out_for_delivery}</p>
              )} */}
            {/* </div>
                <div>
                  <label
                    htmlFor="delivered"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Delivered
                  </label>
                  <input
                    type="number"
                    id="delivered"
                    name="delivered"
                    value={formData.delivered}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  /> */}
            {/* {reviewErrors.delivered && (
                <p className="text-red-500">{reviewErrors.delivered}</p>
              )} */}
            {/* </div>
                <div>
                  <label
                    htmlFor="undelivered"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Undelivered
                  </label>
                  <input
                    type="number"
                    id="undelivered"
                    name="undelivered"
                    value={formData.undelivered}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  /> */}
            {/* {reviewErrors.undelivered && (
                <p className="text-red-500">{reviewErrors.undelivered}</p>
              )} */}
            {/* </div>
                <div>
                  <label
                    htmlFor="out_for_pickup"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Out For Pickup
                  </label>
                  <input
                    type="number"
                    id="out_for_pickup"
                    name="out_for_pickup"
                    value={formData.out_for_pickup}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  /> */}
            {/* {reviewErrors.out_for_pickup && (
                <p className="text-red-500">{reviewErrors.out_for_pickup}</p>
              )} */}
            {/* </div>
                <div>
                  <label
                    htmlFor="pickup_done"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    PickUp Done
                  </label>
                  <input
                    type="number"
                    id="pickup_done"
                    name="pickup_done"
                    value={formData.pickup_done}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  /> */}
            {/* {reviewErrors.pickup_done && (
                <p className="text-red-500">{reviewErrors.pickup_done}</p> */}
            {/* )} */}
            {/* </div>
                <div>
                  <label
                    htmlFor="pickup_not_done"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    PickUp Not Done
                  </label>
                  <input
                    type="number"
                    id="pickup_not_done"
                    name="pickup_not_done"
                    value={formData.pickup_not_done}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  /> */}
            {/* {reviewErrors.pickup_not_done && (
                <p className="text-red-500">{reviewErrors.pickup_not_done}</p>
              )} */}
            {/* </div>
              </>
            )}  */}

            {formData.status === "closing" && closingData && (
              <>
                <div>
                  <label
                    htmlFor="fresh_load"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Fresh Load
                  </label>
                  <input
                    type="number"
                    id="fresh_load"
                    name="fresh_load"
                    value={selectedVehicleDataForCloseForm?.fresh_load}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {/* {reviewErrors.fresh_load && (
                <p className="text-red-500">{reviewErrors.fresh_load}</p>
              )} */}
                </div>
                <div>
                  <label
                    htmlFor="out_for_delivery"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Out For Delivery
                  </label>
                  <input
                    type="number"
                    id="out_for_delivery"
                    name="out_for_delivery"
                    value={selectedVehicleDataForCloseForm?.out_for_delivery}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {/* {reviewErrors.out_for_delivery && (
                <p className="text-red-500">{reviewErrors.out_for_delivery}</p>
              )} */}
                </div>
                <div>
                  <label
                    htmlFor="delivered"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Delivered
                  </label>
                  <input
                    type="number"
                    id="delivered"
                    name="delivered"
                    value={selectedVehicleDataForCloseForm?.delivered}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {/* {reviewErrors.delivered && (
                <p className="text-red-500">{reviewErrors.delivered}</p>
              )} */}
                </div>
                <div>
                  <label
                    htmlFor="undelivered"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Undelivered
                  </label>
                  <input
                    type="number"
                    id="undelivered"
                    name="undelivered"
                    value={selectedVehicleDataForCloseForm?.undelivered}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {/* {reviewErrors.undelivered && (
                <p className="text-red-500">{reviewErrors.undelivered}</p>
              )} */}
                </div>
                <div>
                  <label
                    htmlFor="out_for_pickup"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Out For Pickup
                  </label>
                  <input
                    type="number"
                    id="out_for_pickup"
                    name="out_for_pickup"
                    value={selectedVehicleDataForCloseForm?.out_for_pickup}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {/* {reviewErrors.out_for_pickup && (
                <p className="text-red-500">{reviewErrors.out_for_pickup}</p>
              )} */}
                </div>
                <div>
                  <label
                    htmlFor="pickup_done"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    PickUp Done
                  </label>
                  <input
                    type="number"
                    id="pickup_done"
                    name="pickup_done"
                    value={selectedVehicleDataForCloseForm?.pickup_done}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {/* {reviewErrors.pickup_done && (
                <p className="text-red-500">{reviewErrors.pickup_done}</p> */}
                  {/* )} */}
                </div>
                <div>
                  <label
                    htmlFor="pickup_not_done"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    PickUp Not Done
                  </label>
                  <input
                    type="number"
                    id="pickup_not_done"
                    name="pickup_not_done"
                    value={selectedVehicleDataForCloseForm?.pickup_not_done}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleInputChange(e)}
                    className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                  />
                  {/* {reviewErrors.pickup_not_done && (
                <p className="text-red-500">{reviewErrors.pickup_not_done}</p>
              )} */}
                </div>
              </>
            )}
          </div>
        </>
      )}{" "}
      <button
        onClick={handleNextClick}
        className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
        disabled={isLoading}
      >
        Next
      </button>
    </>
  );

  const renderOnSuccess = () => (
    <div className="w-full h-[75vh] flex flex-col gap-28 pt-10 animate-slide-in-right">
      <div className="flex flex-col items-center gap-6">
        <CircleCheckBig className="w-16 h-16 text-white animate-bounce" />
        <p className="text-2xl ">Completed</p>
      </div>
      <p className="text-white text-center">
        You have successfully submitted your Information
      </p>
    </div>
  );

  return (
    <div className="max-w-md mx-auto p-6 bg-[#191919] rounded-lg shadow-xl text-gray-100">
      <h2 className="text-[16px] font-medium mb-6 text-center text-white-400">
        DeliveryPlus - Vendor Vehicle Management
      </h2>

      {currentStep === 1 && renderReviewInformation()}
      {currentStep === 2 && renderStepTwo()}
      {/* {currentStep === 3 && renderStepThree()} */}
      {formData.status !== "opening" && currentStep === 3 && renderStepThree()}
      {currentStep === 4 && renderOnSuccess()}

      {currentStep !== 4 && (
        <button
          // onClick={resetForm}
          className="w-full mt-4 px-4 py-2 text-gray-400 hover:text-red-300 flex items-center justify-center border border-gray-400 rounded-md transition-colors"
        >
          <XCircleIcon className="w-5 h-5 mr-1" />
          Reset
        </button>
      )}
      <CustomSelectInput
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        handleRightClick={handleRightClick}
      />
    </div>
  );
};

export default VendorVehicle;